import React, { useEffect, useState } from 'react';
import CN from 'classnames';
import { Avatar } from '@kaya/kaya-ui-design-system-pb';
import { getInitials } from '../../../utils';

// TODO : Need to move to DS
export interface ChipProps {
    className?: string;
    isClosable?: boolean;
    isSelected?: boolean;
    isSelfSelectable?: boolean;
    label: string;
    isMarked?: boolean;
    onAction?: () => void;
    onClick?: () => void;
    imageURL?: string;
}

export const Chip = ({
    label,
    className,
    isMarked,
    isClosable = false,
    isSelfSelectable = true,
    isSelected,
    onClick,
    onAction = undefined,
    imageURL,
}: ChipProps) => {
    const [isChipSelected, setIsChipSelected] = useState<boolean>(false);
    const [isDefault, setIsDefault] = useState<boolean>(false);

    useEffect(() => {
        setIsChipSelected(isSelected || false);
        setIsDefault(isMarked || false);
    }, [isSelected, isMarked]);

    const handleClick = () => {
        onClick?.();
    };

    const handleOnAction = (e: React.MouseEvent) => {
        e.stopPropagation();
        onAction?.();
    };

    const chipBorder = CN({
        'border-N-300': !isChipSelected,
        'border-B-200': isChipSelected,
        'border-N-400': !isSelfSelectable,
        'border-G-400': isDefault,
    });

    const chipBackground = CN({
        'bg-N-50': !isChipSelected,
        'bg-B-200': isChipSelected,
        'bg-N-300': !isSelfSelectable,
        'bg-G-200': isDefault,
    });

    const CommonClassNames = CN(
        'skill__card cursor-pointer rounded min-w-[150px] max-w-fit border py-[8px] rounded-[2px]',
        className,
        chipBackground,
        chipBorder
    );

    const labelContainerClasses = CN('flex items-center gap-x-[4px] py-[1px] px-[12px]');

    const labelCommonClasses = CN('text-xs font-600 mb-[1px]', {
        'text-N-600': !isChipSelected,
        'text-N-700': isChipSelected,
    });

    return (
        <div className={CommonClassNames} onClick={handleClick}>
            <div className={labelContainerClasses}>
                <Avatar
                    imageURL={imageURL}
                    className={`${!isSelfSelectable ? 'bg-N-400 text-black' : 'cursor-pointer'}`}
                    shape="circle"
                    size="sm"
                    type={imageURL ? 'image' : 'text'}
                    icon="ri-user-3-line"
                    textLabel={getInitials(label)}
                />
                <div className="flex flex-col">
                    <p className={labelCommonClasses}>{label}</p>
                    <span
                        onClick={isSelfSelectable ? onAction && handleOnAction : () => {}}
                        className={`${!isSelfSelectable ? 'text-N-600' : 'text-B-600'} text-xs cursor-pointer`}
                    >
                        {isDefault ? 'Default' : `${onAction && isSelfSelectable ? 'Set as default' : 'Selected'}`}
                    </span>
                </div>
            </div>
        </div>
    );
};
