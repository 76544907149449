import { useQuery } from '@apollo/client';
import { useAdminPanelContext } from '../../../../../contexts';
import { GET_EXISTING_KPL_USERS } from '../../../../../graphql';
import { MultiSelectableInput } from '@kaya/kaya-ui-design-system-pb';

const convertToSelectableItems = (data: string[]) => {
    return (
        data?.map((item: string) => {
            return { id: item, name: item };
        }) || []
    );
};

export const UserConfigurationInnerForm = () => {
    const { data } = useQuery(GET_EXISTING_KPL_USERS);
    const { kplFormData, setKPLFormData } = useAdminPanelContext();

    const domainsList = convertToSelectableItems(kplFormData?.domains || []);
    const usersList = convertToSelectableItems(kplFormData?.users || []);
    const users = convertToSelectableItems(data?.getExistingKPLUsers?.users || []).sort((a, b) => a.name.localeCompare(b.name));
    const domains = convertToSelectableItems(data?.getExistingKPLUsers?.domains || []);

    return (
        <div className="flex flex-col gap-y-4">
            <div>
                <p className="text-sm mb-2 text-N-600">
                    <small>
                        From this section, you have the ability to search and give certain users/domains the access for
                        this KPL. Once the changes were saved, you still have the ability to do any changes from the
                        Edit KPL option.
                    </small>
                </p>
            </div>
            <div className="w-full">
                <div className="flex flex-col gap-y-2 w-full">
                    <p className="text-N-600">Select Existing Users</p>
                    <MultiSelectableInput
                        width="w-full"
                        selectedValues={usersList}
                        placeholder="Select Users"
                        selectableItems={users}
                        onSelect={(user: any) => {
                            setKPLFormData(kplFormData => {
                                if (kplFormData.users.includes(user.id)) {
                                    return {
                                        ...kplFormData,
                                        users: kplFormData.users.filter((id: string) => id !== user.id),
                                    };
                                }

                                return {
                                    ...kplFormData,
                                    users: [...kplFormData.users, user.id],
                                };
                            });
                        }}
                        showTags
                        taglength={3}
                    />
                </div>
            </div>
            <div className="w-full">
                <div className="flex flex-col gap-y-2 w-full">
                    <p className="text-N-600">Select Existing Domains</p>
                    <MultiSelectableInput
                        width="w-full"
                        selectedValues={domainsList}
                        placeholder="Select Domains"
                        selectableItems={domains}
                        onSelect={(domain: any) => {
                            setKPLFormData(kplFormData => {
                                if (kplFormData.domains.includes(domain.id)) {
                                    return {
                                        ...kplFormData,
                                        domains: kplFormData.domains.filter((id: string) => id !== domain.id),
                                    };
                                }

                                return {
                                    ...kplFormData,
                                    domains: [...kplFormData.domains, domain.id],
                                };
                            });
                        }}
                        showTags
                        taglength={4}
                    />
                </div>
            </div>
        </div>
    );
};
