import React, { useEffect, useState } from 'react';
import { IMG_KAYA_BOT_GIF } from '../../constants';
import { ChatContentContainer, ExtendedSidebar, Sidebar } from '../common';
import { FullScreenLayout } from '../layout';
import { useMobile } from '../../hooks';
import {
    AdminPanelSectionSelectionTypes,
    ChatContentContextProvider,
    CustomKplSectionCreationSteps,
    useChatContext,
    useCustomKplContext,
    useSideBarContext,
} from '../../contexts';
import { MobileSidebarContainer } from '../common/sidebar/common';

type ChatContentProps = {
    showHeader?: boolean;
    onExternalLinkClick?: () => void;
};

const ChatContent: React.FC<ChatContentProps> = ({ showHeader = false, onExternalLinkClick = () => {} }) => {
    const [isLoading, setIsLoading] = useState(true);
    const isMobile = useMobile();

    const { isCurrentUserGuest } = useChatContext();
    const {
        setAdminPanelSelection,
    } = useSideBarContext();
    const { isGuestKplCreated, currentStep } = useCustomKplContext();

    useEffect(() => {
        if ((currentStep !== CustomKplSectionCreationSteps?.KPL_CREATED && isGuestKplCreated, isCurrentUserGuest)) {
            if (isCurrentUserGuest && !isGuestKplCreated) {
                setAdminPanelSelection(AdminPanelSectionSelectionTypes.CUSTOM_KPL);
            } else if (isCurrentUserGuest && isGuestKplCreated) {
                setAdminPanelSelection(AdminPanelSectionSelectionTypes.NOT_SELECTED);
            }
        }
    }, [isGuestKplCreated, isCurrentUserGuest,currentStep]);

    useEffect(() => {
        const hasSplashScreenBeenShown = sessionStorage.getItem('splashScreenShown');

        if (hasSplashScreenBeenShown) {
            setIsLoading(false);
        } else {
            setTimeout(() => {
                setIsLoading(false);
                sessionStorage.setItem('splashScreenShown', 'true');
            }, 2000);
        }
    }, []);

    if (isLoading) {
        return (
            <div className="animate-jump-in animate-once animate-duration-1000 animate-delay-100 flex justify-center items-center h-full bg-gradient-to-l from-[#2C3E95] to-[#1F2937]">
                <img alt="Kaya Chatbot GIF" src={IMG_KAYA_BOT_GIF} className="rounded-full" />
            </div>
        );
    }

    // Render a full-screen layout with a sidebar, an extended sidebar, and a chat content container
    return (
        <FullScreenLayout>
            {isMobile ? (
                <MobileSidebarContainer />
            ) : (
                <>
                    <Sidebar />
                    <ExtendedSidebar />
                </>
            )}
            <ChatContentContextProvider>
                <ChatContentContainer />
            </ChatContentContextProvider>
        </FullScreenLayout>
    );
};

export { ChatContent };
