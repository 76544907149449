import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, SpinLoader, UIModalV3 } from '@kaya/kaya-ui-design-system-pb';
import React, { useEffect, useState } from 'react';
import 'react-diff-view/style/index.css';
import {
    BitbucketProject,
    BitbucketPullRequest,
    BitbucketRepository,
    CodeAnalysisType,
} from '../../../__generated__/graphql';
import { CSS_INPUT } from '../../../constants';
import { useChatContext } from '../../../contexts';
import {
    ANALYZE_PULL_REQUEST,
    GET_BITBUCKET_PROJECTS,
    GET_JIRA_TICKET_INFO,
    GET_REPO_PULL_REQUESTS,
} from '../../../graphql';
import { downloadCodeAnalysisReport } from '../../../services';
import { CN } from '../../../utils';
import { ChatMessage, CommonMessage, FormattedChatMessage, ScrollableWrapper } from '../../common';
import { LoadingState } from './LoadingState';
import { PullRequestDetails } from './PullRequestDetails';
import { ANALYSIS_OPTIONS } from './data';

interface DynamicCodeAnalysisMessage {
    id: string;
    type: CodeAnalysisType;
    content?: any;
}

const generateId = () => `${Date.now()}-${Math.random()}`;

const WelcomeMessage = () => {
    const message = `**🚀 AI-Powered Pull Request Analysis Hub**

**🔧 Overview**: An all-in-one tool that automates pull request analysis to ensure high-quality, seamless integrations. It checks code quality, flags merge conflicts, assesses change impact, verifies requirement alignment, and ensures CI/CD readiness.

### ✨ Key Analyses:

1. **Code Quality Analysis** - Keeps code clean, efficient, and secure 🔒  
2. **Merge Conflict Analysis** - Identifies potential merge conflicts early 🔄  
3. **Change Impact Analysis** - Shows how changes affect other components 🔍  
4. **Requirement Compliance** - Ensures alignment with project goals ✅  
5. **Review Efficiency** - Optimizes the review process by reducing bottlenecks 🔄  
6. **Testing & Validation** - Confirms robust test coverage 🧪  
7. **CI/CD Compliance** - Ensures code is deployment-ready 🌐  

📊 Easy-to-read reports in the Bernie app help you make confident, informed decisions. 👨‍💻💡`;

    return <ChatMessage disableCopy={true} isUser={false} text={message} />;
};

const CodeAnalysis: React.FC = () => {
    const { userDetails } = useChatContext();

    // State variables
    const [formValues, setFormValues] = useState<{ [key: string]: string }>({});
    const [projects, setProjects] = useState<BitbucketProject[]>([]);
    const [repositories, setRepositories] = useState<BitbucketRepository[]>([]);
    const [pullRequests, setPullRequests] = useState<BitbucketPullRequest[]>([]);
    const [selectedPullRequest, setSelectedPullRequest] = useState<string>('');
    const [analysisLoading, setAnalysisLoading] = useState<boolean>(false);
    const [isChangingPR, setIsChangingPR] = useState<boolean>(false);
    const [jiraFormValues, setJiraFormValues] = useState<{ [key: string]: string }>({});
    const [currentModelContent, setCurrentModelContent] = useState<any>({
        enabled: false,
        title: 'Example',
    });

    // Messages array to store steps
    const [messages, setMessages] = useState<DynamicCodeAnalysisMessage[]>([]);

    // GraphQL queries
    const [getBitbucketProjects, { loading: projectsLoading }] = useLazyQuery(GET_BITBUCKET_PROJECTS);
    const [getRepoPullRequests, { loading: pullRequestsLoading }] = useLazyQuery(GET_REPO_PULL_REQUESTS);
    const [getJiraTicketInfo, { loading: jiraLoading }] = useLazyQuery(GET_JIRA_TICKET_INFO);
    const [analyzePullRequest] = useMutation(ANALYZE_PULL_REQUEST);

    // Fetch projects on component mount and initialize messages
    useEffect(() => {
        if (userDetails?.id) {
            getBitbucketProjects({
                variables: {
                    input: {
                        userId: userDetails.id,
                    },
                },
                onCompleted: (data: any) => {
                    if (data && data.getBitbucketProjects) {
                        setProjects(data.getBitbucketProjects);
                    }
                },
            });
        }

        setMessages([
            { id: generateId(), type: CodeAnalysisType.Welcome },
            { id: CodeAnalysisType.Configuration, type: CodeAnalysisType.Configuration }, // Use a fixed ID for configuration
        ]);
    }, [userDetails?.id]); // Removed getBitbucketProjects from dependencies

    // Event handlers
    const handleProjectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const projectKey = e.target.value;
        setFormValues(prev => ({
            ...prev,
            project: projectKey,
            repository: '',
            pull_request: '',
        }));
        const project = projects.find(p => p.key === projectKey);
        if (project && project.repositories && project.repositories.length > 0) {
            setRepositories((project.repositories || []) as BitbucketRepository[]);
        } else {
            setRepositories([]);
        }
        setPullRequests([]);
    };

    const handleRepositoryChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const repositoryName = e.target.value;
        setFormValues(prev => ({
            ...prev,
            repository: repositoryName,
            pull_request: '',
        }));
        if (repositoryName) {
            getRepoPullRequests({
                variables: {
                    input: {
                        userId: userDetails.id,
                        repository: repositoryName,
                    },
                },
                onCompleted: (data: any) => {
                    if (data && data.getRepoPullRequests) {
                        setPullRequests(data.getRepoPullRequests);
                    }
                },
            });
        } else {
            setPullRequests([]);
        }
    };

    const handlePullRequestChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const pullRequestId = e.target.value;
        setFormValues(prev => ({
            ...prev,
            pull_request: pullRequestId,
        }));
        setSelectedPullRequest(pullRequestId);
    };

    const onConfigurePullRequest = () => {
        const pullRequestContent = pullRequests.find(pr => pr.id === selectedPullRequest);
        if (pullRequestContent) {
            setIsChangingPR(false);

            // Replace the configuration message with pull request details
            setMessages(prevMessages => {
                const withoutConfig = prevMessages.filter(msg => msg.type !== CodeAnalysisType.Configuration);
                return [
                    ...withoutConfig,
                    {
                        id: generateId(),
                        type: CodeAnalysisType.PullRequestDetails,
                        content: pullRequestContent,
                    },
                    { id: CodeAnalysisType.AnalysisOptions, type: CodeAnalysisType.AnalysisOptions }, // Fixed ID
                ];
            });
        }
    };

    const fetchJiraTicket = async () => {
        try {
            const { data } = await getJiraTicketInfo({
                variables: {
                    input: {
                        userId: userDetails.id,
                        ticketId: jiraFormValues.jiraTicketId,
                    },
                },
            });

            const description = `Title: ${data?.getJiraTicketInfo?.summary || ''}\nDescription: ${
                data?.getJiraTicketInfo?.description || ''
            }\nStatus: ${data?.getJiraTicketInfo?.status || ''}\nKey: ${
                data?.getJiraTicketInfo?.key || ''
            }\nAssignee: ${data?.getJiraTicketInfo?.assignee || ''}\nReporter: ${
                data?.getJiraTicketInfo?.reporter || ''
            }\nSubtasks: ${
                data?.getJiraTicketInfo?.subtasks?.map(
                    (subtask: any) =>
                        `\nTitle: ${subtask.summary || ''}\nDescription: ${subtask.description || ''}\nKey: ${subtask.key || ''}\nStatus: ${subtask.status || ''}`
                ) || '-'
            }`;

            setJiraFormValues(prev => ({
                ...prev,
                description,
            }));
        } catch (error) {
            console.log(error);
        }
    };

    const executeAnalysis = async (analysisType: string) => {
        setAnalysisLoading(true);

        try {
            // Remove analysis options
            setMessages(prevMessages => prevMessages.filter(msg => msg.type !== CodeAnalysisType.AnalysisOptions));

            // Add loading message
            setMessages(prevMessages => [
                ...prevMessages,
                {
                    id: CodeAnalysisType.Loading,
                    type: CodeAnalysisType.Loading,
                    content: { message: 'Running Analysis...' },
                },
            ]);

            const { data } = await analyzePullRequest({
                variables: {
                    input: {
                        type: analysisType,
                        pullRequestInfo: {
                            id: selectedPullRequest,
                            repository: formValues.repository,
                            userId: userDetails.id,
                        },
                        analysisInfo: {
                            jiraDescription: jiraFormValues.description,
                        },
                    },
                },
            });

            setAnalysisLoading(false);
            const result = data?.analyzePullRequest || 'Could not fetch analysis results. Please try again.';

            setMessages(prevMessages => {
                const withoutLoading = prevMessages.filter(msg => msg.type !== CodeAnalysisType.Loading);
                return [
                    ...withoutLoading,
                    {
                        id: generateId(),
                        type: CodeAnalysisType.AnalysisResult,
                        content: { analysisType, result },
                    },
                    { id: CodeAnalysisType.AnalysisOptions, type: CodeAnalysisType.AnalysisOptions }, // Add analysis options back
                ];
            });
        } catch (error) {
            console.log(error);
            setAnalysisLoading(false);
        }
    };

    const onAnalysisOptionClick = (analysisType: string) => {
        if (analysisType === 'Requirement Compliance Analysis') {
            setCurrentModelContent({
                enabled: true,
                title: 'Requirement Compliance Analysis',
            });
            return;
        }

        executeAnalysis(analysisType);
    };

    const changePullRequest = () => {
        setIsChangingPR(true);

        // Remove existing configuration and analysis options messages
        setMessages(prevMessages => {
            return prevMessages.filter(
                msg => msg.type !== CodeAnalysisType.Configuration && msg.type !== CodeAnalysisType.AnalysisOptions
            );
        });

        // Add configuration step again with fixed ID
        setMessages(prevMessages => [
            ...prevMessages,
            { id: CodeAnalysisType.Configuration, type: CodeAnalysisType.Configuration },
        ]);
    };

    return (
        <ScrollableWrapper className="py-4">
            {messages.map(message => (
                <React.Fragment key={message.id}>
                    {(() => {
                        switch (message.type) {
                            case CodeAnalysisType.Welcome:
                                return <WelcomeMessage />;
                            case CodeAnalysisType.Configuration:
                                return (
                                    <CommonMessage
                                        isUser
                                        disableCopy
                                        text={
                                            isChangingPR
                                                ? 'Please select a new Bitbucket repository and pull request to continue the analysis.'
                                                : 'Please select your Bitbucket repository and pull request to begin the analysis.'
                                        }
                                        content={
                                            <div className="w-[400px]">
                                                <div className="my-2 text-md text-center">
                                                    👇 Set up your pull request to start the analysis
                                                </div>
                                                <div className="w-full">
                                                    <div className="flex items-center w-full my-2">
                                                        <label htmlFor="project" className="font-bold w-1/3">
                                                            Project:
                                                        </label>
                                                        <select
                                                            id="project"
                                                            className={CN(
                                                                `${CSS_INPUT} w-full bg-card-default text-N-900`
                                                            )}
                                                            value={formValues.project || ''}
                                                            onChange={handleProjectChange}
                                                            required
                                                            disabled={projectsLoading}
                                                        >
                                                            <option value="">
                                                                {projectsLoading
                                                                    ? 'Loading Projects...'
                                                                    : 'Select Project'}
                                                            </option>
                                                            {projects.map(project => (
                                                                <option key={project.key} value={project.key}>
                                                                    {project.name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                    <div className="flex items-center w-full my-2">
                                                        <label htmlFor="repository" className="font-bold w-1/3">
                                                            Repository:
                                                        </label>
                                                        <select
                                                            id="repository"
                                                            className={CN(
                                                                `${CSS_INPUT} w-full bg-card-default text-N-900`
                                                            )}
                                                            value={formValues.repository || ''}
                                                            onChange={handleRepositoryChange}
                                                            required
                                                            disabled={!formValues.project || pullRequestsLoading}
                                                        >
                                                            <option value="">
                                                                {pullRequestsLoading
                                                                    ? 'Loading Repositories...'
                                                                    : 'Select Repository'}
                                                            </option>
                                                            {repositories.map(repo => (
                                                                <option key={repo.name} value={repo.name}>
                                                                    {repo.name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                    <div className="flex items-center w-full my-2">
                                                        <label htmlFor="pull_request" className="font-bold w-1/3">
                                                            Pull Request:
                                                        </label>
                                                        <select
                                                            id="pull_request"
                                                            className={CN(
                                                                `${CSS_INPUT} w-full bg-card-default text-N-900`
                                                            )}
                                                            value={formValues.pull_request || ''}
                                                            onChange={handlePullRequestChange}
                                                            required
                                                            disabled={!formValues.repository || pullRequestsLoading}
                                                        >
                                                            <option value="">
                                                                {pullRequestsLoading
                                                                    ? 'Loading Pull Requests...'
                                                                    : 'Select Pull Request'}
                                                            </option>
                                                            {pullRequests.map(pr => (
                                                                <option key={pr.id} value={pr.id}>
                                                                    {pr.title}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                    <Button
                                                        onClick={onConfigurePullRequest}
                                                        className="bg-B-500 hover:bg-B-400 py-2 px-4 cursor-pointer text-white border-none rounded-md w-full"
                                                        isDisabled={
                                                            !formValues.project ||
                                                            !formValues.repository ||
                                                            !formValues.pull_request
                                                        }
                                                    >
                                                        Proceed <i className="ri-forward-end-mini-line mx-2"></i>
                                                    </Button>
                                                </div>
                                            </div>
                                        }
                                    />
                                );
                            case CodeAnalysisType.PullRequestDetails:
                                return <PullRequestDetails pullRequest={message.content} />;
                            case CodeAnalysisType.AnalysisOptions:
                                return (
                                    <CommonMessage
                                        isUser={false}
                                        disableCopy
                                        text="Select an analysis to run:"
                                        content={
                                            <div className="flex flex-col gap-6">
                                                <section>
                                                    <h2 className="text-2xl font-bold text-N-800 mb-5">
                                                        Ready to Analyze Your Code? 🚀
                                                    </h2>
                                                    <div className="grid grid-cols-3 gap-4">
                                                        {ANALYSIS_OPTIONS.map(option => (
                                                            <div key={option.name} className="relative">
                                                                <Button
                                                                    onClick={() => onAnalysisOptionClick(option.name)}
                                                                    className={`flex items-center justify-center w-full bg-B-600 hover:bg-B-500 text-white font-medium py-3 px-5 rounded-md shadow-sm transition duration-200 
                        ${analysisLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
                                                                    isDisabled={analysisLoading}
                                                                    data-tip={option.tooltip}
                                                                    data-for={`tooltip-${option.name}`}
                                                                >
                                                                    <i
                                                                        className={`${option.iconClass} mr-3 text-xl`}
                                                                        aria-hidden="true"
                                                                    ></i>
                                                                    {option.name}
                                                                    {analysisLoading && (
                                                                        <SpinLoader
                                                                            size={20}
                                                                            color="#ffffff"
                                                                            className="ml-3"
                                                                        />
                                                                    )}
                                                                </Button>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </section>

                                                {/* Reset Pull Request Section */}
                                                <section>
                                                    <h2 className="text-2xl font-bold text-N-800 mb-5">
                                                        Need to Reset Your Pull Request? 🔄
                                                    </h2>
                                                    <div className="grid grid-cols-3 gap-4">
                                                        <Button
                                                            onClick={changePullRequest}
                                                            className=" flex items-center justify-center bg-R-600 hover:bg-R-500 text-white font-medium py-3 px-5 rounded-md shadow-sm transition duration-200"
                                                        >
                                                            <i
                                                                className="ri-recycle-line mr-3 text-xl"
                                                                aria-hidden="true"
                                                            ></i>
                                                            Change Pull Request
                                                        </Button>
                                                    </div>
                                                </section>
                                            </div>
                                        }
                                    />
                                );
                            case CodeAnalysisType.AnalysisResult:
                                return (
                                    <CommonMessage
                                        key={message.id}
                                        isUser={false}
                                        disableCopy
                                        text={`${message.content.analysisType} Result`}
                                        content={
                                            <>
                                                <div className="w-[80%]">
                                                    <FormattedChatMessage text={message.content.result} />
                                                    <div className="flex gap-2 justify-between my-2">
                                                        <Button
                                                            onClick={() =>
                                                                downloadCodeAnalysisReport(
                                                                    `${message.content.analysisType} Report`,
                                                                    message.content.result
                                                                )
                                                            }
                                                            className="hover:bg-B-200 py-2 px-4 cursor-pointer text-N-700 border border-B-500 rounded-md w-full"
                                                        >
                                                            Download Analysis Report{' '}
                                                            <i className="ri-download-2-line mx-2"></i>
                                                        </Button>
                                                    </div>
                                                </div>
                                            </>
                                        }
                                    />
                                );
                            case CodeAnalysisType.Loading:
                                return <LoadingState analysisLoading={analysisLoading} />;
                            default:
                                return null;
                        }
                    })()}
                </React.Fragment>
            ))}
            <UIModalV3
                header={
                    <div className="flex items-center gap-x-2">
                        <i className="ri-article-line text-md" />
                        {currentModelContent.title}
                    </div>
                }
                isOpen={currentModelContent.enabled}
                onClose={() => setCurrentModelContent((c: any) => ({ ...c, enabled: false }))}
                setOpen={() => setCurrentModelContent((c: any) => ({ ...c, enabled: false }))}
                width="560px"
                boxedBody
            >
                <div className="my-2">
                    <div className="flex justify-between gap-4">
                        <input
                            type="text"
                            placeholder="Enter Jira Issue ID"
                            className="border border-N-400 rounded-md px-3 py-2 w-2/3"
                            onChange={(e: any) => {
                                setJiraFormValues(prev => ({
                                    ...prev,
                                    jiraTicketId: e.target.value,
                                }));
                            }}
                        />
                        <Button
                            className="bg-B-500 hover:bg-B-600 text-white rounded-md px-4 py-2 w-1/3"
                            onClick={fetchJiraTicket}
                            isLoading={jiraLoading}
                        >
                            <i className="ri-arrow-down-double-fill mr-2"></i>
                            Fetch Ticket
                        </Button>
                    </div>
                    <textarea
                        disabled={jiraLoading}
                        className="border border-N-400 rounded-md px-3 py-2 mt-4 w-full h-40"
                        placeholder="Requirements"
                        value={jiraFormValues.description || ''}
                        onChange={(e: any) => {
                            setJiraFormValues(prev => ({
                                ...prev,
                                description: e.target.value,
                            }));
                        }}
                    />
                    <Button
                        onClick={() => {
                            executeAnalysis(currentModelContent.title);
                            setCurrentModelContent((c: any) => ({ ...c, enabled: false }));
                        }}
                        className=" flex items-center justify-center w-full bg-B-600 hover:bg-B-500 text-white font-medium py-3 px-5 rounded-md shadow-sm transition duration-200"
                    >
                        Proceed <i className="ri-forward-end-mini-line mx-2"></i>
                    </Button>
                </div>
            </UIModalV3>
        </ScrollableWrapper>
    );
};

export { CodeAnalysis };
