import React, { useMemo, useState } from 'react';
import { Avatar, Button, Dropdown, Tooltip } from '@kaya/kaya-ui-design-system-pb';
import { useChatHeader, useMobile } from '../../hooks';
import { CN, truncateWithEllipsis, uniqueId } from '../../utils';
import { MobileMenuButton, UserFormContainer } from '../common';
import {
    AdminPanelModalActions,
    AdminPanelSectionSelectionTypes,
    useAdminPanelContext,
    useChatContext,
    useCustomKplContext,
    useSideBarContext,
} from '../../contexts';
import { KPLFormContainer } from '../common/KPL-management-container/common/kpl-form-container';
import { PromptFormContainer } from '../common/prompt-management-container/common/prompt-form-container/PromptFormContainer';
import { CustomKplChatHeader } from '../common/custom-kpl-container/common/custom-kpl-chat-haader/CustomKplChatHeader';
import { FeatureType } from '../../__generated__/graphql';

const ChatHeader = () => {
    const [isNewKplModalOpen, setIsNewKplModalOpen] = useState(false);
    const [isNewPromptModalOpen, setIsNewPromptModalOpen] = useState(false);
    const [isUserFormDrawerOpen, setIsUserFormDrawerOpen] = useState(false);

    const { setSelectedKPLAction, setSelectedPromptAction, setSelectedUserAction } = useAdminPanelContext();
    const { adminPanelSelection } = useSideBarContext();
    const { title, label, currentModelImage, dropDownModels, description, featureType } = useChatHeader();
    const isMobile = useMobile();
    const { headerMetaDataList } = useCustomKplContext();
    const { isCurrentUserGuest } = useChatContext();

    const handleNewKplModal = () => {
        switch (adminPanelSelection) {
            case AdminPanelSectionSelectionTypes.KPL_AUTHORING:
                setSelectedKPLAction(AdminPanelModalActions.CREATE);
                setIsNewKplModalOpen(true);
                break;
            case AdminPanelSectionSelectionTypes.USER_AUTHORING:
                setSelectedUserAction(AdminPanelModalActions.CREATE);
                setIsUserFormDrawerOpen(true);
                break;
            case AdminPanelSectionSelectionTypes.PROMPT_AUTHORING:
                setSelectedPromptAction(AdminPanelModalActions.CREATE);
                setIsNewPromptModalOpen(true);
                break;
        }
    };

    const getAdminHeaderInfo = () => {
        switch (adminPanelSelection) {
            case AdminPanelSectionSelectionTypes.KPL_AUTHORING:
                return {
                    title: 'KPL Authoring',
                    subTitle: 'Add, Edit and View all KPLs',
                    btnName: 'Add KPL',
                    icon: 'ri-archive-stack-line',
                };

            case AdminPanelSectionSelectionTypes.USER_AUTHORING:
                return {
                    title: 'User Authoring',
                    subTitle: 'Add, Edit and View all Users',
                    btnName: 'Add New User',
                    icon: 'ri-user-add-line',
                };

            case AdminPanelSectionSelectionTypes.PROMPT_AUTHORING:
                return {
                    title: 'Prompt Authoring',
                    subTitle: 'Add, Edit and View all Prompt',
                    btnName: 'Add Prompt',
                    icon: 'ri-article-line',
                };
            case AdminPanelSectionSelectionTypes.EXTERNAL_USER:
                return {
                    title: 'External User Requests',
                    subTitle: 'Approve, Reject external user requests',
                };
        }
    };

    const adminHeaderInfo = useMemo(() => getAdminHeaderInfo(), [adminPanelSelection]);

    return (
        <React.Fragment>
            <div
                className={CN(
                    'chat_content__header bg-white border-b border-b-N-200 flex items-center justify-between sm:py-3 sm:px-3 max-h-[69px] px-6 py-6',
                    {
                        '!p-4 !max-h-full': isCurrentUserGuest,
                    }
                )}
            >
                {adminPanelSelection === AdminPanelSectionSelectionTypes.NOT_SELECTED ? (
                    <React.Fragment>
                        <div className="flex items-center gap-x-2 sm:items-center">
                            {isCurrentUserGuest && featureType !== FeatureType?.General ? (
                                <CustomKplChatHeader />
                            ) : (
                                <>
                                    <Avatar
                                        shape="square"
                                        imageURL={currentModelImage}
                                        type="image"
                                        size={isMobile ? 'md' : 'lg'}
                                        className={'min-w-[48px]'}
                                    />
                                    <div className="flex flex-col">
                                        <h6 className="text-N-600 font-500 text-lg sm:text-md" title={title}>
                                            {headerMetaDataList?.isGuestKPLCreated && label !== FeatureType.General
                                                ? truncateWithEllipsis(String(label), isMobile ? 20 : 100)
                                                : truncateWithEllipsis(title, isMobile ? 25 : 80)}
                                        </h6>

                                        <div className="flex gap-2 items-center">
                                            <div className="flex items-center flex-wrap gap-2">
                                                <div className="relative flex gap-x-1 justify-center items-center cursor-pointer py-[1px] px-2 rounded bg-B-100">
                                                    <p className="text-xs text-B-700 relative top-[1px] text-nowrap">
                                                        {truncateWithEllipsis(String(label), isMobile ? 20 : 100)}
                                                    </p>
                                                    <Tooltip
                                                        id={uniqueId()}
                                                        place="bottom"
                                                        subText={description}
                                                        variant="dark"
                                                        tooltipType="detailed"
                                                    >
                                                        <i className="ri-question-line text-sm text-B-700 relative" />
                                                    </Tooltip>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                        <div>
                            <Dropdown
                                defaultSelectedItem={1}
                                wrapperClassName="absolute right-[20px] min-w-[200px]"
                                header={{
                                    type: 'title',
                                    title: (
                                        <p className="text-md text-center font-500 text-N-900 p-0 m-0 pt-1">
                                            Select Model
                                        </p>
                                    ),
                                }}
                                dropdownItems={dropDownModels}
                                selectionType="single"
                                triggerAction="onClick"
                                showSelectedCheck
                            >
                                <i className="px-2 cursor-pointer text-h6 text-N-600 ri-equalizer-3-fill" />
                            </Dropdown>
                            {isMobile && <MobileMenuButton />}
                        </div>
                    </React.Fragment>
                ) : (
                    adminPanelSelection !== AdminPanelSectionSelectionTypes?.CUSTOM_KPL && (
                        <div className="flex items-center justify-between sm:w-full w-full">
                            <div>
                                <h6 className="text-h6 text-N-600 font-500">{adminHeaderInfo?.title}</h6>
                                <p className="text-sm text-N-600">{adminHeaderInfo?.subTitle}</p>
                            </div>
                            <div className="flex items-center gap-x-2">
                                {adminHeaderInfo?.btnName && (
                                    <Button
                                        onClick={handleNewKplModal}
                                        iconBefore={!isMobile ? adminHeaderInfo?.icon : 'ri-add-line'}
                                        appearance="primary"
                                        size="medium"
                                        view="solid"
                                    >
                                        {!isMobile && adminHeaderInfo?.btnName}
                                    </Button>
                                )}
                                {isMobile && <MobileMenuButton />}
                            </div>
                        </div>
                    )
                )}
                {adminPanelSelection === AdminPanelSectionSelectionTypes?.CUSTOM_KPL && isCurrentUserGuest && (
                    <>
                        <CustomKplChatHeader />
                        {isMobile && <MobileMenuButton />}
                    </>
                )}
            </div>

            {/* KPL Add/Edit from modal */}
            <KPLFormContainer isNewKplModalOpen={isNewKplModalOpen} setIsNewKplModalOpen={setIsNewKplModalOpen} />
            <UserFormContainer
                isUserFormDrawerOpen={isUserFormDrawerOpen}
                setIsUserFormDrawerOpen={setIsUserFormDrawerOpen}
            />
            <PromptFormContainer
                isPromptViewModalOpen={isNewPromptModalOpen}
                setPromptViewModalOpen={setIsNewPromptModalOpen}
            />
        </React.Fragment>
    );
};

export { ChatHeader };
