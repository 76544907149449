import { ApolloProvider } from '@apollo/client';
import Keycloak from 'keycloak-js';
import { ToastContainer } from 'react-toastify';
import { ChatbotFull, ChatbotPopup } from '../components';
import {
    AdminPanelContextProvider,
    ChatContextProvider,
    CustomKplContextProvider,
    IChatbotUserDetails,
    SideBarContextProvider,
} from '../contexts';
import { getApolloClient } from '../graphql';
import { UserContext } from '../contexts/UserContext.tsx';

export type IChatbot = {
    popup?: boolean;
    showHeader?: boolean;
    keycloakClient?: Keycloak;
    userDetails: IChatbotUserDetails;
};

const Chatbot = ({ popup = false, showHeader = false, keycloakClient, userDetails }: IChatbot) => {
    if (!keycloakClient || !keycloakClient.token) {
        return <></>;
    }

    return (
        <ApolloProvider client={getApolloClient(String(keycloakClient.token))}>
            <UserContext keycloakClient={keycloakClient}>
                <SideBarContextProvider>
                    <AdminPanelContextProvider>
                        <ChatContextProvider userDetails={userDetails}>
                            <CustomKplContextProvider>
                                <ToastContainer
                                    autoClose={300}
                                    hideProgressBar
                                    bodyClassName="text-N-900 font-500 text-sm"
                                />
                                <>{popup ? <ChatbotPopup /> : <ChatbotFull showHeader={showHeader} />}</>
                            </CustomKplContextProvider>
                        </ChatContextProvider>
                    </AdminPanelContextProvider>
                </SideBarContextProvider>
            </UserContext>
        </ApolloProvider>
    );
};

export default Chatbot;
