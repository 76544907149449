import { useEffect, useRef, useState } from 'react';
import 'react-diff-view/style/index.css';
import { CommonMessage } from '../../common';

interface ILoadingState {
    analysisLoading: boolean;
}

const loadingMessages = [
    'Connecting to Repository 🔗',
    'Fetching Pull Request 📂',
    'Analyzing Code Structure 📈',
    'Generating Detailed Report 📄',
    'Finalizing Insights 💡',
];

const calculateProgress = (currentIndex: number, totalMessages: number) => {
    return Math.round(((currentIndex + 1) / totalMessages) * 100);
};

const useLoadingMessages = (loading: boolean, messages: string[]) => {
    const [loadingState, setLoadingState] = useState<{
        currentMessage: string;
        showLoadingText: boolean;
        progress: number;
    }>({
        currentMessage: '',
        showLoadingText: false,
        progress: 0,
    });

    const intervalRef = useRef<number | null>(null);

    useEffect(() => {
        let currentIndex = 0;

        const startLoading = () => {
            intervalRef.current = window.setInterval(() => {
                currentIndex = (currentIndex + 1) % messages.length;
                setLoadingState({
                    currentMessage: messages[currentIndex],
                    showLoadingText: true,
                    progress: calculateProgress(currentIndex, messages.length),
                });
            }, 5000);
            // Set initial state immediately
            setLoadingState({
                currentMessage: messages[currentIndex],
                showLoadingText: true,
                progress: calculateProgress(currentIndex, messages.length),
            });
        };

        const stopLoading = () => {
            if (intervalRef.current !== null) {
                clearInterval(intervalRef.current);
                intervalRef.current = null;
            }
            setLoadingState({ currentMessage: '', showLoadingText: false, progress: 0 });
        };

        if (loading) {
            startLoading();
        } else {
            stopLoading();
        }

        return () => {
            if (intervalRef.current !== null) {
                clearInterval(intervalRef.current);
                intervalRef.current = null;
            }
        };
    }, [loading]); // Removed 'messages' from dependencies

    return loadingState;
};

const LoadingState = ({ analysisLoading }: ILoadingState) => {
    const { currentMessage, progress } = useLoadingMessages(analysisLoading, loadingMessages);

    return (
        <CommonMessage
            isUser={false}
            disableCopy
            text="Running Analysis..."
            content={
                <div>
                    {currentMessage}
                    <div className="bg-N-400 rounded-full h-2.5 animate-fade my-1">
                        <div className="bg-B-600 h-2.5 rounded-full animate-pulse" style={{ width: `${progress}%` }} />
                    </div>
                </div>
            }
        />
    );
};

export { LoadingState };
