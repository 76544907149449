import { MobileMenuButton } from '../buttons';
import { CustomKplSectionSelectionTypes, useCustomKplContext } from '../../../contexts';
import { SidebarOption } from './common/sidebar-option/SidebarOption';

export const CustomKplMenu = () => {
    const { isGuestKplCreated, isGuestKplExpired } = useCustomKplContext();
    return (
        <div>
            <div className="flex justify-between items-center border-b border-b-N-200 z-[10]">
                <div className="chat_history_title py-[20px] sm:py-4 px-6 sm:px-3 flex gap-x-[10px] items-center border-b-N-200">
                    <p className="text-h6 sm:text-lg font-600 text-N-900">Custom KPL</p>
                </div>
                <MobileMenuButton />
            </div>
            <div className="flex flex-col gap-y-5">
                {/* KPL Management */}
                <div className="flex flex-col gap-y-3 px-6 py-4">
                    <div className="flex items-center gap-x-1">
                        <i className="ri-archive-stack-fill text-lg text-N-600" />
                        <p className="text-md text-N-600">Custom KPL Management</p>
                    </div>
                    <SidebarOption
                        selectionType={CustomKplSectionSelectionTypes?.CREATE}
                        isDisabled={isGuestKplCreated || isGuestKplExpired}
                    />
                    <SidebarOption
                        selectionType={CustomKplSectionSelectionTypes?.CONFIGURE}
                        isDisabled={!isGuestKplCreated || isGuestKplExpired}
                    />
                </div>
            </div>
        </div>
    );
};
