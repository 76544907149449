export const ANALYSIS_OPTIONS = [
    {
        name: 'Code Quality Analysis',
        iconClass: 'ri-code-line',
        tooltip: 'Analyze the overall code quality and maintainability.',
    },
    {
        name: 'Merge Conflict Analysis',
        iconClass: 'ri-error-warning-line',
        tooltip: 'Detect and resolve merge conflicts in the codebase.',
    },
    {
        name: 'Change Impact Analysis',
        iconClass: 'ri-lightbulb-flash-line',
        tooltip: 'Assess the impact of changes on the existing system.',
    },
    {
        name: 'Requirement Compliance Analysis',
        iconClass: 'ri-checkbox-circle-line',
        tooltip: 'Ensure the code complies with specified requirements.',
    },
    {
        name: 'Review Pull Request',
        iconClass: 'ri-git-pull-request-line',
        tooltip: 'Review and approve pull requests from team members.',
    },
    {
        name: 'Review Efficiency Analysis',
        iconClass: 'ri-group-line',
        tooltip: 'Analyze the efficiency of code reviews and identify areas for improvement.',
    },
    {
        name: 'Testing and Validation Analysis',
        iconClass: 'ri-test-tube-line',
        tooltip: 'Analyze the effectiveness of testing and validation processes.',
    },
    {
        name: 'Compliance with CI/CD Standards',
        iconClass: 'ri-git-branch-line',
        tooltip: 'Ensure compliance with CI/CD standards and best practices.',
    },
];
