import React, { useState } from 'react';
import { Dropdown } from '@kaya/kaya-ui-design-system-pb';
import { DropdownItemProps } from '@kaya/kaya-ui-design-system-pb/dist/src/components/atoms/Dropdown/DropdownItem';
import { FeatureType, Source } from '../../../__generated__/graphql';
import { truncateWithEllipsis } from '../../../utils';
import { useChatContentContext, useChatContext } from '../../../contexts';

export type CustomSource = Source & { page: number };

export const ReferenceCard = ({
    id,
    source,
    pdfHighlightedReferences,
}: {
    id: number;
    source: CustomSource;
    pdfHighlightedReferences?: any[];
}) => {
    const {
        setIsPDFReferenceModalOpen,
        setGetHighlightedPDFReferenceInputValues,
        setHighlightedPDFReferencesThumbnails,
    } = useChatContentContext();

    const dropdownItems: DropdownItemProps[] =
        source?.sources?.map((value, index) => ({
            id: index,
            label: truncateWithEllipsis(String(value)),
            icon: 'ri-links-fill text-lg',
            leadingNodeType: 'icon',
            onClick: () => window.open(String(value), '_blank'),
        })) || [];

    const getFileName = (url: string) => {
        try {
            const regex = /\/([^\/?#;]+(\.pdf|\.txt|\.csv|\.docx|\.xlsx))(?:\?|#|;|$)/i;
            const match = url.match(regex);
            return match ? match[1] : url;
        } catch (error) {
            console.error('Error occurred while getting file name:', error);
            return url;
        }
    };

    const getSanitizedURL = (url: string) => {
        try {
            const fileName = getFileName(String(source.domainUrl));
            const sanitizedFileName = decodeURIComponent(fileName).includes('/')
                ? decodeURIComponent(fileName).split('/').pop()
                : decodeURIComponent(fileName);
            return fileName ? truncateWithEllipsis(String(sanitizedFileName), 50) : url;
        } catch (error) {
            console.error('Error occurred while sanitizing URL:', error);
            return url;
        }
    };

    const getReference = (source: CustomSource) => {
        return `${getSanitizedURL(String(source.domainUrl))} ${source.page ? ` | Page: ${source.page}` : ''}`;
    };

    const getReferenceColor = (source: CustomSource) => {
        if (
            pdfHighlightedReferences?.[0]?.feature === FeatureType.ClientKbPerceptive &&
            !String(source.domainUrl).includes('kaya-aia-knowledge-base-docs')
        ) {
            return {
                border: 'border-A-400',
                bg: 'bg-A-200',
                text: 'text-A-600',
                source: 'text-A-700',
            };
        }

        return {
            border: 'border-B-400',
            bg: 'bg-B-200',
            text: 'text-B-600',
            source: 'text-B-700',
        };
    };

    const isPDFReference = (url: string) => {
        return url.toLowerCase().split('?')[0].endsWith('.pdf');
    };

    const handleReferencePDFView = () => {
        setHighlightedPDFReferencesThumbnails(pdfHighlightedReferences);
        setGetHighlightedPDFReferenceInputValues({
            sourceId: String(source.id),
            sourceName: getReference(source),
            feature: pdfHighlightedReferences?.[0].feature || '',
        });
        setIsPDFReferenceModalOpen(true);
    };

    const referenceColor = getReferenceColor(source);

    return (
        <React.Fragment>
            {isPDFReference(String(source.domainUrl)) ? (
                // if the reference is PDF
                <div
                    onClick={handleReferencePDFView}
                    className={`flex items-center ${referenceColor.border} rounded-[4px] border cursor-pointer`}
                >
                    <div
                        className={`${referenceColor.bg} px-2 rounded-[3px_0px_0px_3px] text-xs py-[1px] leading-4 pt-1 cursor-pointer ${referenceColor.text}`}
                    >
                        <p>{id}</p>
                    </div>
                    <div className="px-2 text-xs">
                        <p className={`${referenceColor.source}`}>{getReference(source)}</p>
                    </div>
                </div>
            ) : (
                // if the reference is not PDF
                <Dropdown
                    wrapperClassName="min-w-[200px]"
                    triggerAction="onClick"
                    selectionType="single"
                    openningPosition="above"
                    dropdownItems={dropdownItems}
                >
                    <div className={`flex items-center ${referenceColor.border} rounded-[4px] border cursor-pointer`}>
                        <div
                            className={`${referenceColor.bg} px-2 rounded-[3px_0px_0px_3px] text-xs py-[1px] leading-4 pt-1 cursor-pointer ${referenceColor.text}`}
                        >
                            <p>{id}</p>
                        </div>
                        <div className="px-2 text-xs">
                            <p className={`${referenceColor.source}`}>{getReference(source)}</p>
                        </div>
                    </div>
                </Dropdown>
            )}
        </React.Fragment>
    );
};
