import Keycloak, { KeycloakLogoutOptions } from 'keycloak-js';
import { KAYA_KEYCLOAK_CLIENT_ID, KAYA_KEYCLOAK_REALM, KAYA_KEYCLOAK_URL } from '../constants';
import { logger } from '../utils';
import { SignupMethods } from '../__generated__/graphql.ts';

export enum keycloakIDPStatus {
    NEW = 'NEW',
    OLD = 'OLD',
}

export interface IKeycloakAttributes {
    idp?: SignupMethods[];
    status?: keycloakIDPStatus[];
}

export interface IKeycloakUser {
    id: string;
    username: string;
    firstName: string;
    lastName: string;
    email: string;
    emailVerified: boolean;
    attributes: IKeycloakAttributes;
}

class KeycloakService {
    public static keycloak: Keycloak = new Keycloak({
        realm: KAYA_KEYCLOAK_REALM!,
        url: KAYA_KEYCLOAK_URL!,
        clientId: KAYA_KEYCLOAK_CLIENT_ID!,
    });

    // eslint-disable-next-line @typescript-eslint/ban-types
    public static initKeycloak = (onAuthenticatedCallback: Function) => {
        this.keycloak
            .init({
                onLoad: 'login-required',
            })
            .then(() => {
                onAuthenticatedCallback();
            })
            .catch(logger.error);
    };

    public static updateToken = async (validity: number) => await this.keycloak.updateToken(validity);

    public static getLoginUrl = () => this.keycloak.createLoginUrl();

    public static register = () => this.keycloak.register();

    public static doLogin = this.keycloak.login;

    public static doLogout = (options: KeycloakLogoutOptions) => {
        localStorage.removeItem('userId');
        this.keycloak.logout(options);
    };

    public static getToken = async () => {
        if (this.keycloak.authenticated) {
            if (this.keycloak.isTokenExpired()) {
                await this.updateToken(30);
            }
            return this.keycloak.token;
        } else {
            return '';
        }
    };

    public static getRefreshToken = () => this.keycloak.refreshToken || '';

    public static isLoggedIn = () => !!this.keycloak.token;

    public static getUsername = () => this.keycloak.tokenParsed?.preferred_username;

    public static getUserInfo = () => this.keycloak.loadUserInfo();

    public static getUserProfile = () => this.keycloak.loadUserProfile();

    public static getParsedToken = () => this.keycloak.tokenParsed;
}

export default KeycloakService;
