import { useLazyQuery, useMutation } from '@apollo/client';
import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { FeatureType } from '../__generated__/graphql';
import {
    CREATE_CUSTOM_KPL,
    ENHANCE_PROMPT,
    GENERATE_QUESTIONS_FOR_CUSTOM_KPLS,
    GET_CUSTOM_KPL_CONFIG_DATA,
    GET_GUEST_USER_METADATA,
    UPDATE_CUSTOM_KPL,
} from '../graphql';
import { useAllKPLs, useChatHeader, useMobile } from '../hooks';
import { KeycloakService } from '../services';
import { getFilteredFeaturesByEmail, removeVariables, toConstantCase } from '../utils';
import { useChatContext } from './ChatContext';
import {
    AdminPanelSectionSelectionTypes,
    CustomKplSectionCreationSteps,
    CustomKplSectionSelectionTypes,
    SideBarButtonSelectionTypes,
    useSideBarContext,
} from './SideBarContext';

type ICustomKplContext = {
    files: File[];
    setFiles: Function;
    name: string;
    setName: Function;
    removedFile: string[];
    setRemovedFiles: Function;
    promptInstructions: string;
    setPromptInstructions: Function;
    conversationStarters: any[];
    isLoading: boolean;
    handleSave: Function;
    handleUpdate: Function;
    isKplCreated: boolean;
    setIsKplCreated: Function;
    isPromptFormatted: boolean;
    setIsPromptFormatted: Function;
    handleSubmit: Function;
    currentStep: CustomKplSectionCreationSteps;
    isFileUploaded: boolean;
    tempPromptInstructions: string;
    enhancedPromptInstructions: string;
    handlePromptEnhancement: Function;
    fetchConversationStarters: Function;
    isQuestionGenerated: boolean;
    errorText: string;
    headerMetaDataList: any;
    setHeaderMetaDataList: Function;
    isGuestKplCreated: boolean;
    isKplSaved: boolean;
    isKplLoading: boolean;
    promptEnhanceLoading: boolean;
    navigateToKpl: Function;
    setIsQuestionGenerated: Function;
    updatedFiles: File[];
    setUpdatedFiles: Function;
    isQuestionLoading: boolean;
    setIsQuestionLoading: Function;
    fetchSavedKplData: Function;
    kplSaved: boolean;
    kplConfigKey: string;
    fetchHeaderMetaData: Function;
    isFormEdited: boolean;
    setIsFormEdited: Function;
    failedFileUploads: string[];
    unreadableFiles: string[];
    isGuestKplExpired: boolean;
    initialFiles: File[];
    setInitialFiles: Function;
};

type ICustomKplContextProvider = {
    children: ReactNode;
};

const CustomKplContext = createContext<ICustomKplContext | null>(null);

export const CustomKplContextProvider: React.FC<ICustomKplContextProvider> = ({ children }) => {
    const isMobile = useMobile();
    const { currentSelectedModel } = useChatHeader();
    const {
        currentMessage,
        setCurrentMessage,
        chatDocuments,
        currentUserConfig,
        appConfig,
        kplListLoading,
        refetchApplicationConfigs,
        handleAppConfigSelection,
        isShowKplGuildLines,
    } = useChatContext();

    const [files, setFiles] = useState<File[]>([]);
    const [removedFile, setRemovedFiles] = useState<string[]>([]);
    const [updatedFiles, setUpdatedFiles] = useState<File[]>([]);
    const [name, setName] = useState<string>('');
    const [promptInstructions, setPromptInstructions] = useState<string>('');
    const [tempPromptInstructions, setTempPromptInstructions] = useState<string>('');
    const [enhancedPromptInstructions, setEnhancedPromptInstructions] = useState<string>('');
    const [isQuestionGenerated, setIsQuestionGenerated] = useState<boolean>(false);
    const [isPromptFormatted, setIsPromptFormatted] = useState<boolean>(false);
    const [conversationStarters, setConversationStarters] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isQuestionLoading, setIsQuestionLoading] = useState<boolean>(false);
    const [isKplCreated, setIsKplCreated] = useState<boolean>(false);
    const [errorText, setErrorText] = useState<string>('');
    const [isFileUploaded, setIsFileUploaded] = useState<boolean>(false);
    const [isFormEdited, setIsFormEdited] = useState<boolean>(false);
    const [headerMetaDataList, setHeaderMetaDataList] = useState<any>({});
    const [isGuestKplCreated, setIsGuestKplCreated] = useState<boolean>(false);
    const [kplSaved, setKplSaved] = useState<boolean>(false);
    const [kplConfigKey, setKplConfigKey] = useState<string>('');
    const [currentStep, setCurrentStep] = useState<CustomKplSectionCreationSteps>(
        CustomKplSectionCreationSteps?.CREATE_NAME
    );
    const [failedFileUploads, setFailedFileUploads] = useState<string[]>([]);
    const [unreadableFiles, setUnreadableFiles] = useState<string[]>([]);

    const [updateCustomKpl] = useMutation(UPDATE_CUSTOM_KPL);
    const [createCustomKplQuestion] = useMutation(GENERATE_QUESTIONS_FOR_CUSTOM_KPLS);
    const [createCustomKpl, { loading: isKplSaved }] = useMutation(CREATE_CUSTOM_KPL);
    const [enhancePrompt, { loading: promptEnhanceLoading }] = useMutation(ENHANCE_PROMPT);
    const [isGuestKplExpired, setIsGuestKplExpired] = useState<boolean>(false);
    const [initialFiles, setInitialFiles] = useState<File[]>([]);
    const [getGuestCustomKplMetaData, { loading: isKplLoading }] = useLazyQuery(GET_GUEST_USER_METADATA, {
        fetchPolicy: 'network-only',
    });
    const [getGuestCustomKplConfigData] = useLazyQuery(GET_CUSTOM_KPL_CONFIG_DATA, {
        fetchPolicy: 'network-only',
    });

    const customKplName = localStorage.getItem('custom_kpl_name');
    const customKplPrompt = localStorage.getItem('custom_kpl_prompt');

    const { doesKPLConfigExist } = useAllKPLs();

    const {
        setSideBarButtonSelection,
        isCollapsed,
        setIsCollapsed,
        sideBarButtonSelection,
        setAdminPanelSelection,
        handleMobileMenuIconClick,
        setCustomKplSelection,
    } = useSideBarContext();

    const fetchHeaderMetaData = async (isFetchNewData?: boolean) => {
        const { data } = await getGuestCustomKplMetaData({
            variables: { userId: KeycloakService.keycloak.tokenParsed?.sub || '' },
        });

        setHeaderMetaDataList(data?.getKPLGuestUserMetaData);
        setIsGuestKplCreated(data?.getKPLGuestUserMetaData?.isGuestKPLCreated ?? false);
        setConversationStarters(data?.getKPLGuestUserMetaData?.questions ?? []);
        setIsGuestKplExpired(data?.getKPLGuestUserMetaData?.isExpired ?? false);

        if (isFetchNewData) {
            await fetchSavedKplData();
            setIsQuestionGenerated(false);
        }
    };

    const fetchSavedKplData = async () => {
        const configKeys: string[] = [
            ...new Set(
                currentUserConfig?.features
                    .split(', ')
                    .map(key => key.trim())
                    .filter(key => key !== '')
            ),
        ];

        const templateKey = configKeys.filter(key => key !== FeatureType.General);

        const { data } = await getGuestCustomKplConfigData({
            variables: {
                templateKey: `${templateKey?.[0]}_${currentSelectedModel}_PROMPT`,
            },
        });

        let fetchedData = await data?.getPromptTemplateByKey;

        const configKey = await headerMetaDataList?.guestKPLNames?.filter((key: string) => key !== FeatureType.General);
        const currentConfigTitle = appConfig?.find(config => config?.configKey === configKey?.[0])?.title;
        setName(currentConfigTitle ?? '');
        setPromptInstructions(removeVariables(fetchedData?.templateContent)?.withoutVariables);
        setFiles(headerMetaDataList?.kplUserDocument);
        setInitialFiles(headerMetaDataList?.kplUserDocument);
    };

    const fetchConversationStarters = async () => {
        setIsQuestionLoading(true);
        try {
            await createCustomKplQuestion({
                variables: {
                    kplConfigKey: `${headerMetaDataList?.guestKPLNames?.[headerMetaDataList?.guestKPLNames.length - 1]}`,
                },
                onCompleted: data => {
                    setIsQuestionGenerated(true);
                    setConversationStarters(data?.generateQuestionsForCustomKPL || []);
                },
                onError: () => {
                    setErrorText('Something went wrong. Please try again later.');
                    setIsLoading(false);
                },
            });
        } catch (error) {
            console.log(error);
        } finally {
            setIsQuestionLoading(false);
        }
    };

    const handleSave = async () => {
        setIsLoading(true);
        try {
            await createCustomKpl({
                variables: {
                    input: {
                        documents: [...files],
                        configKey: toConstantCase(name),
                        userId: KeycloakService.keycloak.tokenParsed?.sub || '',
                        title: name,
                        removedDocuments: null,
                        prompt: promptInstructions,
                        predefinedQuestions: null,
                        showPreDefinedQuestions: true,
                    },
                },
                onCompleted: data => {
                    setCurrentStep(CustomKplSectionCreationSteps.KPL_CREATED);
                    setIsLoading(false);
                    setKplSaved(true);
                    setIsKplCreated(true);
                    setFailedFileUploads(
                        (data?.createCustomKPL?.failedFileUploads || []).filter((file): file is string => file !== null)
                    );
                    setUnreadableFiles(
                        (data?.createCustomKPL?.unreadableFiles || []).filter((file): file is string => file !== null)
                    );
                },
                onError: () => {
                    if (doesKPLConfigExist(toConstantCase(name))) {
                        setErrorText('KPL key is already exist with this title');
                    } else {
                        setErrorText('Something went wrong. Please try again later.');
                    }
                    setIsLoading(false);
                },
            });
        } catch (error) {
            console.log(error);
        }
    };

    const handleUpdate = async () => {
        setIsLoading(true);
        setFailedFileUploads([]);
        setUnreadableFiles([]);
        try {
            await updateCustomKpl({
                variables: {
                    input: {
                        documents: [...updatedFiles],
                        configKey: headerMetaDataList?.guestKPLNames?.[headerMetaDataList?.guestKPLNames.length - 1],
                        userId: KeycloakService.keycloak.tokenParsed?.sub || '',
                        title: name,
                        removedDocuments: removedFile,
                        prompt: promptInstructions,
                        predefinedQuestions: conversationStarters,
                        showPreDefinedQuestions: isShowKplGuildLines,
                    },
                },
                onCompleted: data => {
                    toast.success('KPL has been updated successfully');
                    setIsFormEdited(false);
                    setFailedFileUploads(
                        (data?.updateCustomKPL?.failedFileUploads || []).filter(
                            (file: any): file is string => file !== null
                        )
                    );
                    setUnreadableFiles(
                        (data?.updateCustomKPL?.unreadableFiles || []).filter(
                            (file: any): file is string => file !== null
                        )
                    );
                },
                onError: async () => {
                    toast.error('Something went wrong. Please try again later.');
                    setErrorText('Something went wrong. Please try again later.');
                    await fetchSavedKplData();
                },
            });
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
            fetchHeaderMetaData(true);
            refetchApplicationConfigs();
            setRemovedFiles([]);
            setUpdatedFiles([]);
        }
    };

    useEffect(() => {
        if (!kplListLoading) {
            fetchSavedKplData();
        }
    }, [kplListLoading]);

    const handleSubmit = async () => {
        const textToSend = currentMessage.trim();

        switch (currentStep) {
            case CustomKplSectionCreationSteps.CREATE_NAME:
                setName(textToSend);
                if (!doesKPLConfigExist(toConstantCase(textToSend))) {
                    localStorage.setItem('custom_kpl_name', textToSend);
                    setKplConfigKey(toConstantCase(textToSend));
                    setCurrentStep(CustomKplSectionCreationSteps.RESPONSE_CONFIGURE);
                }
                break;
            case CustomKplSectionCreationSteps.RESPONSE_CONFIGURE:
                setTempPromptInstructions(textToSend);
                localStorage.setItem('custom_kpl_prompt', textToSend);
                await handlePromptEnhancement(textToSend, true);
                break;

            case CustomKplSectionCreationSteps.UPLOAD_DOCUMENTS:
                setIsFileUploaded(true);
                setCurrentStep(CustomKplSectionCreationSteps.CONFIRM_PROCESSED);
                break;
        }

        setCurrentMessage('');
    };

    const handlePromptEnhancement = async (prompt?: string, isCreate?: boolean) => {
        setCurrentMessage('');
        try {
            const { data } = await enhancePrompt({
                variables: {
                    prompt: prompt ?? tempPromptInstructions,
                },
                fetchPolicy: 'no-cache',
            });
            if (data?.enhancePrompt) {
                setEnhancedPromptInstructions(data?.enhancePrompt);
                setPromptInstructions(data?.enhancePrompt);
                setCurrentStep(CustomKplSectionCreationSteps.UPLOAD_DOCUMENTS);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const navigateToKpl = () => {
        setAdminPanelSelection(AdminPanelSectionSelectionTypes.NOT_SELECTED);
        setSideBarButtonSelection(SideBarButtonSelectionTypes.KplListSelection);
        setCustomKplSelection(CustomKplSectionSelectionTypes.CONFIGURE);
        setIsGuestKplCreated(true);
        fetchHeaderMetaData(true);

        const features = getFilteredFeaturesByEmail(currentUserConfig, appConfig, isGuestKplCreated);

        const selectedKpl = features?.find(
            feature => feature?.configKey === currentUserConfig?.metadata?.default?.feature
        );

        if (!isMobile) {
            if (selectedKpl) {
                handleAppConfigSelection(selectedKpl);
                handleMobileMenuIconClick();
            }
            if (isCollapsed) {
                setIsCollapsed(false);
            } else {
                SideBarButtonSelectionTypes.KplListSelection === sideBarButtonSelection && setIsCollapsed(true);
            }
        } else {
            setIsCollapsed(false);
        }
    };

    useEffect(() => {
        fetchHeaderMetaData();

        if (headerMetaDataList?.questions && headerMetaDataList?.questions?.length > 0) {
            if (files?.length > 1) {
                setIsQuestionGenerated(false);
            }
        }
    }, []);

    useEffect(() => {
        fetchSavedKplData();
    }, [headerMetaDataList]);

    useEffect(() => {
        setIsQuestionGenerated(conversationStarters?.length !== 0);
    }, [conversationStarters]);

    useEffect(() => {
        setFiles(chatDocuments);
    }, [chatDocuments]);

    return (
        <CustomKplContext.Provider
            value={{
                files,
                setFiles,
                name,
                setName,
                promptInstructions,
                setPromptInstructions,
                conversationStarters,
                isLoading,
                handleSave,
                handleUpdate,
                isKplCreated,
                setIsKplCreated,
                isPromptFormatted,
                setIsPromptFormatted,
                handleSubmit,
                currentStep,
                isFileUploaded,
                tempPromptInstructions,
                enhancedPromptInstructions,
                handlePromptEnhancement,
                fetchConversationStarters,
                isQuestionGenerated,
                errorText,
                headerMetaDataList,
                setHeaderMetaDataList,
                isGuestKplCreated,
                isKplSaved,
                isKplLoading,
                navigateToKpl,
                promptEnhanceLoading,
                removedFile,
                setRemovedFiles,
                setIsQuestionGenerated,
                updatedFiles,
                setUpdatedFiles,
                isQuestionLoading,
                setIsQuestionLoading,
                fetchSavedKplData,
                kplSaved,
                kplConfigKey,
                fetchHeaderMetaData,
                isFormEdited,
                setIsFormEdited,
                failedFileUploads,
                unreadableFiles,
                isGuestKplExpired,
                initialFiles,
                setInitialFiles,
            }}
        >
            {children}
        </CustomKplContext.Provider>
    );
};

export const useCustomKplContext = (): ICustomKplContext => {
    const context = useContext(CustomKplContext);

    if (!context) {
        throw new Error('useAdminPanelContext must be used within a CustomKplContextProvider');
    }

    return context;
};
