import { useMutation } from '@apollo/client';
import { ADD_NEW_USER } from '../graphql';
import React, { ReactNode, useEffect, useState } from 'react';
import { IKeycloakUser, keycloakIDPStatus } from '../services/keycloak.ts';
import { FeatureType, ModelType, NewUserInput, UserType } from '../__generated__/graphql.ts';
import Keycloak from 'keycloak-js';
import { PageLoading } from '../components/common/full-page-loader';

interface IUserContext {
    children: ReactNode;
    keycloakClient: Keycloak;
}

export const UserContext: React.FC<IUserContext> = ({ children, keycloakClient }) => {
    const [addNewUser] = useMutation(ADD_NEW_USER);
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    useEffect(() => {
        const checkAuth = async () => {
            const keycloakProfile: IKeycloakUser = (await keycloakClient?.loadUserProfile()) as IKeycloakUser;

            if (
                keycloakProfile.attributes?.status?.includes(keycloakIDPStatus.NEW) &&
                keycloakProfile.attributes?.idp &&
                keycloakProfile.attributes?.idp[0]
            ) {
                try {
                    const userInputData: NewUserInput = {
                        id: keycloakProfile.id,
                        email: keycloakProfile.email,
                        firstName: keycloakProfile.firstName,
                        lastName: keycloakProfile.lastName,
                        password: 'password',
                        features: [FeatureType.General],
                        models: [ModelType.OpenAi, ModelType.OpenAiOmni],
                        metadata: {
                            default: {
                                model: ModelType.OpenAi,
                                feature: FeatureType.General,
                                webSearch: true,
                                dynamicPrompts: false,
                                noLLM: false,
                            },
                            userType: UserType.User,
                        },
                        override: true,
                        isTempPassword: false,
                        isGuestUser: false,
                        signupMethod: keycloakProfile.attributes?.idp[0],
                    };

                    const { errors } = await addNewUser({ variables: { input: userInputData } });

                    if (errors) {
                        throw Error('Error adding new user');
                    }

                    setIsAuthenticated(true);
                } catch (error) {
                    console.error(error);
                    await keycloakClient.logout();
                }
            } else {
                setIsAuthenticated(true);
            }
        };

        checkAuth().then(() => console.debug('User login verified'));
    }, []);

    if (!isAuthenticated) {
        return <PageLoading />;
    } else {
        return <>{children}</>;
    }
};
