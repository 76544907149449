import Tree, { mutateTree, RenderItemParams, TreeData } from '@atlaskit/tree';
import { Button, UIModalV3 } from '@kaya/kaya-ui-design-system-pb';
import React, { useState } from 'react';
import { Diff, DiffType, Hunk, HunkData, parseDiff } from 'react-diff-view';
import 'react-diff-view/style/index.css';
import Split from 'react-split';
import { BitbucketPullRequest } from '../../../__generated__/graphql';
import { CommonMessage, FormattedChatMessage } from '../../common';

interface IPullRequestDetails {
    pullRequest: BitbucketPullRequest;
}

const getFileName = (oldName: string, newName: string): string => {
    if (oldName === '/dev/null') return `[New] ${newName}`;
    if (newName === '/dev/null') return `[Deleted] ${oldName}`;
    if (oldName === newName) return newName;
    return `${oldName} → ${newName}`;
};

const PullRequestDetails: React.FC<IPullRequestDetails> = ({ pullRequest }) => {
    const [isPRModelOpen, setPRModelOpen] = useState<boolean>(false);
    const [selectedFile, setSelectedFile] = useState<any>(null);

    if (!pullRequest) return null;

    // Parse the diff text to get file information
    const files = parseDiff(pullRequest.diffText);

    // Construct the tree data from the parsed diff files
    const [fileTree, setFileTree] = useState<TreeData>({
        rootId: 'root',
        items: {
            root: {
                id: 'root',
                children: files.map((_, index) => `file-${index}`),
                isExpanded: true,
            },
            ...files.reduce(
                (acc, file, index) => {
                    const fileId = `file-${index}`;
                    acc[fileId] = {
                        id: fileId,
                        data: {
                            title: getFileName(file.oldPath, file.newPath),
                            file,
                        },
                        children: [],
                        isExpanded: true,
                    };
                    return acc;
                },
                {} as Record<string, any>
            ),
        },
    });

    const message = `### 🎉 Pull Request Configured Successfully! \n\nPull Request **#${pullRequest.id} - ${pullRequest.title}** has been configured.`;

    // Handle file selection
    const handleFileSelect = (file: File) => {
        setSelectedFile(file);
    };

    // Render the file tree component
    const renderTree = (tree: TreeData) => (
        <Tree
            tree={tree}
            renderItem={({ item, provided }: RenderItemParams) => (
                <div
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    ref={provided.innerRef}
                    className="cursor-pointer hover:bg-B-50 p-2 rounded transition-colors duration-150"
                    onClick={() => handleFileSelect(item.data.file)}
                >
                    {item.data.title}
                </div>
            )}
            onExpand={itemId => setFileTree(mutateTree(fileTree, itemId, { isExpanded: true }))}
            onCollapse={itemId => setFileTree(mutateTree(fileTree, itemId, { isExpanded: false }))}
            isDragEnabled={false}
            isNestingEnabled={false}
        />
    );

    return (
        <>
            <CommonMessage
                isUser={false}
                disableCopy
                text={message}
                content={
                    <>
                        <FormattedChatMessage text={message} />
                        <Button
                            className="hover:bg-B-200 py-2 px-4 cursor-pointer text-N-700 border border-B-500 rounded-md w-[400px]"
                            onClick={() => setPRModelOpen(true)}
                        >
                            View Pull Request <i className="ri-external-link-line mx-2"></i>
                        </Button>
                    </>
                }
            />
            <UIModalV3
                header={
                    <div className="flex items-center gap-x-2">
                        <i className="ri-article-line text-md" />
                        <a
                            className="font-semibold text-md cursor-pointer text-B-600 hover:underline transition"
                            target="_blank"
                            href={pullRequest.url}
                        >
                            Pull Request #{pullRequest.id}
                        </a>
                    </div>
                }
                isOpen={isPRModelOpen}
                onClose={() => setPRModelOpen(false)}
                setOpen={() => setPRModelOpen(false)}
                width="1080px"
                boxedBody
            >
                <>
                    <div className="my-2 pb-2 flex flex-col space-y-2 text-md border-b border-b-N-400">
                        <p>
                            <span className="font-semibold mx-1">Title:</span>
                            <b>{pullRequest.title}</b>
                        </p>
                        <p>
                            <span className="font-semibold mx-1">Author:</span>
                            <b>{pullRequest.author}</b>
                        </p>

                        <div className="flex">
                            <p>
                                <span className="font-semibold mx-1">Source Branch:</span>
                                <b>{pullRequest.sourceBranch}</b>
                            </p>
                            <span className="mx-2">→</span>
                            <p>
                                <span className="font-semibold mx-1">Destination Branch:</span>
                                <b>{pullRequest.destinationBranch}</b>
                            </p>
                        </div>
                    </div>

                    <Split className="flex max-h-[400px]" sizes={[35, 65]}>
                        <div className="border-r border-N-400 p-2 max-h-full overflow-y-scroll">
                            {renderTree(fileTree)}
                        </div>
                        <div className="p-2 bg-gray-50 max-h-full overflow-y-scroll">
                            {selectedFile ? (
                                <Diff
                                    viewType="unified"
                                    diffType={selectedFile.type as DiffType}
                                    hunks={selectedFile.hunks as HunkData[]}
                                >
                                    {hunks => hunks.map(hunk => <Hunk key={hunk.content} hunk={hunk} />)}
                                </Diff>
                            ) : (
                                <p className="text-center text-N-600 italic">Select a file to view the diff</p>
                            )}
                        </div>
                    </Split>
                </>
            </UIModalV3>
        </>
    );
};

export { PullRequestDetails };
